import React, { useState, useEffect, useContext } from "react";
import { useParams, useHistory, Link } from "react-router-dom";
import AuthContext from "../contexts/auth";
import api from "../services/config.url";
import NewsLetter from "../components/NewsLetter";
import { FormattedPrice } from "../components/FormatValues";
import showPopup from "../components/PopUp";
import bookModel from "./../assets/images/books/grid/imageNotFound.jpg";
import ModalPdf from "../components/modal/modalPdf";

function ShopDetail() {
  const { user, setCart, cart, setSizeCart, setLoading } =
    useContext(AuthContext);
  const navegate = useHistory();
  const { productId } = useParams();
  const [product, setProduct] = useState({});
  const [isOpen, setIsOpen] = useState({ active: false, pdf: "" });
  const [childrenProduct, setChildrenProduct] = useState([]);

  useEffect(() => {
    setLoading(true);
    api
      .get(`/customers/products/slug/${productId}`)
      .then((response) => {
        setProduct(response.data);
      })
      .catch((error) => {
        showPopup(error.response.data.message, "error");
      })
      .finally(() => {
        setLoading(false);
      });
  }, [productId]);

  useEffect(() => {
    setLoading(true);
    api
      .get(`/customers/products?current=1&pageSize=100&parent=${productId}`)
      .then((response) => {
        filterChildrens(response);
      })
      .catch((error) => {
        showPopup(error?.response?.data?.message, "error");
      })
      .finally(() => {
        setLoading(false);
      });
  }, [productId]);

  const filterChildrens = (response) => {
    const filteredAsc = response.data.records.sort((a, b) => a.sku - b.sku);
    setChildrenProduct(filteredAsc);
  };

  const addCart = async (data) => {
    if (!user) {
      showPopup(
        "Acesse sua conta para adicionar produtos ao carrinho",
        "alert"
      );
      return navegate.push("/acesso");
    }

    const cartId = cart._id;
    setLoading(true);
    try {
      const response = await api.post(`/customers/cart/${cartId}/item`, {
        product: data._id,
        amountUnit: data.amount,
        sku: data.sku,
        name: data.name,
        materia: data.materia,
        description: data.description,
      });
      setSizeCart(response.data.items.length);
      setCart(response.data);
      showPopup("Produto adicionado ao carrinho", "success");
    } catch (error) {
      if (error?.response?.data?.message === "Unauthorized") {
        showPopup("Faça login novamente", "error");
        return navegate.push("/acesso");
      }
      showPopup(error.response.data.message, "error");
    } finally {
      setLoading(false);
    }
  };

  const handleDownloadCloud = async (obj) => {
    const sku = obj.sku ?? obj.product?.sku;
    try {
      setLoading(true);
      const response = await api.post(
        `/customers/products/cloud-pdf-sample`,
        {
          sku,
        },
        { "Content-Type": "application/pdf" }
      );

      const pdfBase64 = response.data.downloadUrl;

      setIsOpen({ active: true, pdf: pdfBase64 });
    } catch (error) {
      const BackendError = error?.response?.data?.message;
      const genericError = "Erro ao baixar os arquivos";
      showPopup(BackendError || genericError, "error");
    } finally {
      setLoading(false);
    }
  };

  const handleAddCart = async (product) => {
    try {
      setLoading(true);

      const cartItemIds = cart?.items.map((item) =>
        item?.product?._id?.toString()
      );

      const pendingToInclude = product.freeWith.filter(
        (x) => !cartItemIds.includes(x._id?.toString())
      );

      if (pendingToInclude.length > 0) {
        let response;

        for (let i = 0; i < pendingToInclude.length; i++) {
          const { _id, amount, sku, materia, name, description } =
            pendingToInclude[i] ?? {};

          response = await api.post(`/customers/cart/${cart._id}/item`, {
            product: _id,
            amountUnit: amount,
            sku: sku,
            name: name,
            materia: materia,
            description: description,
          });
        }
        setCart(response.data);
        showPopup(
          `${
            pendingToInclude.length > 1
              ? "Produtos adicionados"
              : "Produto adicionado"
          } ao carrinho`,
          "success"
        );
        setCart(response.data);
      }
    } catch (error) {
      showPopup(error?.response?.data?.message, "error");
    } finally {
      setLoading(false);
    }
  };

  const ContentReturn = () => {
    if (!product.hasOwnProperty("_id")) {
      return (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "95vh",
          }}
        ></div>
      );
    }

    const freeWith = product?.freeWith?.map((x) => x?._id?.toString());
    const cartItemsId = cart?.items?.map((o) => o?.product?._id?.toString());
    const isProductOnCart = cartItemsId?.includes(product?._id?.toString());
    const validItems = cartItemsId?.filter((x) => freeWith?.includes(x));

    return (
      <div className="header-padding-main">
        <ModalPdf
          pdf={isOpen.pdf}
          isModalOpen={isOpen.active}
          isModalClose={() => setIsOpen(false)}
        />

        <div className="page-content bg-grey">
          <section className="content-inner-1">
            <div className="container">
              <div className="row book-grid-row style-4 m-b60">
                <div className="col">
                  <div className="dz-box">
                    <div className="dz-media2">
                      <img
                        src={
                          (product.photos &&
                            product.photos[0] &&
                            product.photos[0].photo &&
                            product.photos[0].photo.url) ||
                          bookModel
                        }
                        alt="book"
                      />
                    </div>
                    <div className="dz-content">
                      <div className="dz-header">
                        <h4 className="title">{product.name}</h4>
                      </div>
                      <div className="dz-body">
                        <div className="book-footer left-float">
                          <h5>
                            {product.amount !== 0 ? (
                              <>
                                {validItems?.length === freeWith?.length ? (
                                  <p style={{ color: "#389138" }}>
                                    Adicione gratuitamente em seu carrinho!
                                  </p>
                                ) : (
                                  <div className="price">
                                    <FormattedPrice
                                      price={product.amount || 0}
                                    />
                                    {product.originalAmount && (
                                      <p className="p-lr10">
                                        <FormattedPrice
                                          price={product?.originalAmount}
                                        />
                                      </p>
                                    )}
                                  </div>
                                )}
                              </>
                            ) : (
                              <>
                                <p style={{ color: "#389138" }}>
                                  Cadernos gratuitos, por tempo limitado.
                                  Aproveite!
                                </p>
                              </>
                            )}
                          </h5>
                        </div>
                      </div>
                      {childrenProduct.length < 1 && (
                        <React.Fragment>
                          {product.hasSample && (
                            <div
                              className="clickHere mt-3"
                              onClick={() => handleDownloadCloud(product)}
                            >
                              <p
                                style={{
                                  fontWeight: 100,
                                  boxShadow: "0px 2px 5px silver",
                                }}
                              >
                                VEJA AQUI A <b>AMOSTRA</b> DO CAD
                              </p>
                            </div>
                          )}
                        </React.Fragment>
                      )}
                      <div className="dz-body">
                        <div className="book-detail"></div>
                        <p className="text-1">
                          <strong>Conteúdo: </strong>
                          {product.description}
                        </p>
                        <p className="text-1">
                          <strong>Envio e atualizações: </strong>
                          Após a compra, você receberá o link para download do
                          Caderno por e-mail, que também ficará disponível na
                          aba "Meus Estudos". O Caderno ficará disponível para
                          download por seis meses. Para ter acesso às
                          atualizações, bastará fazer o download novamente,
                          sempre que quiser, durante esse período.
                        </p>
                        {product?.freeWith?.length > 0 && (
                          <div>
                            <b
                              style={{
                                display: "block",
                                marginBottom: "15px",
                              }}
                            >
                              Gratuito na compra do(s) seguinte(s) Caderno(s):
                            </b>
                            <div>
                              <div>
                                {product.freeWith
                                  .sort((a, b) => a.sku - b.sku)
                                  .map((item) => {
                                    const isInCart = cart?.items?.some(
                                      (cartItem) => cartItem.sku === item.sku
                                    );

                                    return (
                                      <div
                                        key={item.sku}
                                        style={{
                                          display: "flex",
                                          alignItems: "center",
                                        }}
                                      >
                                        <Link
                                          to={`/livro-detalhes/${item.slug}`}
                                          style={{
                                            color: isInCart
                                              ? "green"
                                              : "inherit",
                                          }}
                                        >
                                          {item.sku} - {item.name}
                                        </Link>
                                        {isInCart && (
                                          <div style={{ marginLeft: "5px" }}>
                                            {" "}
                                            ✅
                                          </div>
                                        )}
                                      </div>
                                    );
                                  })}
                              </div>
                              {!product.freeWith.every((item) =>
                                cart?.items.some(
                                  (cartItem) => cartItem.sku === item.sku
                                )
                              ) && (
                                <button
                                  className="btn btn-primary btnhover m-r20 m-t10 btn-add-cart"
                                  onClick={() => handleAddCart(product)}
                                >
                                  Incluir
                                </button>
                              )}
                            </div>
                          </div>
                        )}

                        <div className="book-footer">
                          <div className="product-num">
                            {isProductOnCart ? (
                              <div
                                className="btn btn-primary"
                                style={{
                                  opacity: "0.5",
                                  cursor: "not-allowed",
                                }}
                              >
                                <span className="m-1">Incluso no carrinho</span>
                              </div>
                            ) : (
                              <div
                                className="btn btn-primary btnhover btnhover2"
                                onClick={() => addCart(product)}
                              >
                                <i className="flaticon-shopping-cart-1"></i>

                                <span className="m-1">
                                  Adicionar no carrinho
                                </span>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="row justify-content-center">
                <div className="col-lx-8">
                  <div className="widget">
                    {childrenProduct.length >= 1 && (
                      <>
                        <h4 className="widget-title no-uppercase">
                          Cadernos inclusos
                        </h4>

                        {childrenProduct.map((book, index) => (
                          <section
                            key={index}
                            className={`row ${
                              book.isAvailableForSale
                                ? "container-books"
                                : "container-books force-grid"
                            }`}
                          >
                            <div className="box-description-book">
                              <p className="title-book">
                                {" "}
                                {book.sku} - {book.name}{" "}
                              </p>
                              <p className="description element-2-description">
                                {" "}
                                {book.description}{" "}
                              </p>
                            </div>
                            <div
                              className={`${
                                book.isAvailableForSale
                                  ? "desktop-sample-btn"
                                  : "force-grid"
                              }`}
                            >
                              {book.isAvailableForSale && (
                                <React.Fragment>
                                  <div style={{ opacity: 0 }}>a</div>
                                  <div style={{ opacity: 0 }}>a</div>
                                </React.Fragment>
                              )}
                              <button className="primary">
                                <Link
                                  to={"#"}
                                  onClick={() => handleDownloadCloud(book)}
                                >
                                  <span>Amostra</span>
                                  {/* <i className="ti-search"></i> */}
                                </Link>
                              </button>
                            </div>
                            {book.isAvailableForSale && (
                              <div className="p-0 atalhos-books">
                                <span className="onlyItem">
                                  {" "}
                                  COMPRA INDIVIDUAL:{" "}
                                </span>

                                <div className="priceOnlyItem">
                                  <h5>
                                    <FormattedPrice price={book.amount} />
                                  </h5>
                                </div>

                                {book.amount !== 0 && (
                                  <>
                                    <button>
                                      <Link
                                        to={"#"}
                                        onClick={() => addCart(book)}
                                      >
                                        Comprar
                                      </Link>
                                    </button>
                                    <button className="m-t10 mobile-sample-btn primary">
                                      <Link
                                        to={"#"}
                                        onClick={() =>
                                          handleDownloadCloud(book)
                                        }
                                      >
                                        <span>Amostra</span>
                                        {/* <i className="ti-search"></i> */}
                                      </Link>
                                    </button>
                                  </>
                                )}
                                {book.amount === 0 && (
                                  <p className="mt-3">
                                    Incluso na compra da coleção completa
                                  </p>
                                )}
                              </div>
                            )}
                          </section>
                        ))}
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </section>
          <NewsLetter />
        </div>
      </div>
    );
  };

  return <ContentReturn />;
}
export default ShopDetail;
