import React, { useState, useMemo, useEffect } from "react";
import { Link } from "react-router-dom";
import api from "../../services/config.url";
import { IoIosAlert } from "react-icons/io";
import showPopup from "../PopUp";
import bookModel from "./../../assets/images/books/grid/imageNotFound.jpg";

export default function RecomendedSlider({ search }) {
  const urlRoute = search ? `search=${search}` : `parent=null`;
  const [products, setProducts] = useState([]);
  const [isLoadingContainer, setIsLoadingContainer] = useState(false);

  useEffect(() => {
    setIsLoadingContainer(true);
    api
      .get(`/customers/products?current=1&pageSize=1000&${urlRoute}`)
      .then((response) => {
        setProducts(response.data.records);
      })
      .catch((error) => {
        // const genericError =
        //   "Sua barra de pesquisa não localizou nenhum produto.";
        // showPopup(error?.response?.data?.message || genericError, "error");
      })
      .finally(() => {
        setIsLoadingContainer(false);
      });
  }, [search]);

  const itemsToShow = useMemo(() => {
    return (
      products
        // .filter((book) => book.amount !== 0)
        .map((book, index) => (
          <Link key={index} to={`livro-detalhes/${book.slug}`}>
            <div className="box">
              <div>
                <img
                  src={
                    (book.photos &&
                      book.photos[0] &&
                      book.photos[0].photo &&
                      book.photos[0].photo.url) ||
                    bookModel
                  }
                  alt="photo"
                />
              </div>
              <div className="box-info">
                <p className="title-info-recomended">{book.name}</p>
                {/* <p className="desc-info-recomended">{book.description}</p> */}
              </div>
            </div>
          </Link>
        ))
    );
  }, [products]);

  return (
    <>
      {isLoadingContainer ? (
        <div className="loadingContainer"></div>
      ) : (
        <>
          <section className="container box-main">
            <div>
              {itemsToShow?.length ? (
                <div className="row grid">{itemsToShow}</div>
              ) : (
                <p className="text-center ">
                  <IoIosAlert size={50} /> {" Ops! Nenhum produto encontrado."}
                </p>
              )}
            </div>
          </section>
        </>
      )}
    </>
  );
}
