import React, { useState, useEffect, useContext } from "react";
import AuthContext from "../contexts/auth";
import api from "../services/config.url";
import { FormattedPrice } from "../components/FormatValues";
import Box from "@mui/material/Box";
import showPopup from "../components/PopUp";
import Modal from "@mui/material/Modal";
import Payments from "../components/payments";
import JSZip from "jszip";

const boxModal = {
  zIndex: "99999",
};
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "80vw",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
};

export default function MyOrders() {
  const { setLoading } = useContext(AuthContext);
  const [orderItems, setOrderItems] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [orderModal, setOrderModal] = useState([]);
  const [pageSize, setPageSize] = useState(5);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const response = await api.get(
          `/customers/orders?current=1&pageSize=${pageSize}`
        );
        setOrderItems(response.data.records);
      } catch (error) {
        const BackendError = error?.response?.data?.message;
        const genericError = "Erro ao listar os pedidos";
        showPopup(BackendError || genericError, "error");
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, [pageSize]);

  const statusMap = {
    WAITING_PAYMENT: "Aguardando Pagamento",
    REGISTERED: "Registrado",
    PAYMENT_APPROVED: "Pagamento Aprovado",
    CANCELLED: "Cancelado",
    PAYMENT_REJECTED: "Pedido Rejeitado",
    DELIVERED: "Entregue",
    PAYMENT_IN_ANALYSIS: "Pagamento em analise",
    CANCEL_REQUESTED: "Pedido cancelado",
  };
  const TranslateStatus = (props) => {
    return <>{statusMap[props.status] || ""}</>;
  };

  const handleOrder = (pedido) => {
    setOrderModal(pedido);
    setIsOpen(true);
  };

  const handleDownload = async (id) => {
    try {
      setLoading(true);
      const zip = new JSZip();
      const response = await api.get(`/customers/products/pdf/${id}`);

      const downloadPromises = response.data.map(async (item) => {
        const response = await fetch(item.url);
        const data = await response.blob();
        zip.file(`${item.name}.pdf`, data);
      });

      await Promise.all(downloadPromises);

      const content = await zip.generateAsync({ type: "blob" });
      const fileURL = window.URL.createObjectURL(content);

      const link = document.createElement("a");
      link.href = fileURL;
      link.setAttribute("download", "Livros.zip");
      link.click();
    } catch (error) {
      const BackendError = error?.response?.data?.message;
      const genericError = "Erro ao baixar os arquivos";
      showPopup(BackendError || genericError, "error");
    } finally {
      setLoading(false);
    }
  };

  const ResumeOrders = () => {
    return (
      <>
        <table className="table-bordered check-tbl mb-4">
          <thead>
            <tr>
              <th>Pedidos</th>
              <th>Status</th>
              <th>Total</th>
            </tr>
          </thead>
          <tbody className="orders">
            {orderItems.map((pedido, index) => (
              <React.Fragment key={index}>
                <tr className="title-order" key={pedido._id}>
                  <th className="p-l10">Código: {pedido._id}</th>
                  <th
                    className={`
                    ${pedido.status == "PAYMENT_APPROVED" ? "approved" : ""} 
                    ${pedido.status == "PAYMENT_REJECTED" ? "rejected" : ""} 
                    ${pedido.status == "CANCELLED" ? "rejected" : ""} 
                    ${pedido.status == "CANCEL_REQUESTED" ? "rejected" : ""} 
                    ${pedido.status == "WAITING_PAYMENT" ? "waiting" : ""}`}
                  >
                    {pedido && pedido.status ? (
                      <div className="paymentsAgain">
                        <TranslateStatus status={pedido.status} />
                        {pedido.status == "WAITING_PAYMENT" && (
                          <>
                            <button onClick={() => handleOrder(pedido)}>
                              Pagar
                            </button>
                          </>
                        )}
                      </div>
                    ) : (
                      "Status não disponível"
                    )}
                  </th>
                  <th className="p-2 text-center " width="13%">
                    <FormattedPrice price={pedido.amountTotal} />
                  </th>
                </tr>
                {pedido.items.map((item) => (
                  <tr key={item._id}>
                    <td>{item.product.name || item.materia}</td>
                    <td>
                      <>
                        {pedido?.status === "PAYMENT_APPROVED" ? (
                          <>
                            <a
                              onClick={() => handleDownload(item.product._id)}
                              target="__blank"
                            >
                              <button
                                className="d-flex justify-content-center downloadPdfBtn max-w100"
                                // disabled={!item?.product?.downloadPdf[0]?.pdf?.url}
                              >
                                Download
                              </button>
                            </a>
                          </>
                        ) : (
                          <div className="d-flex justify-content-center downloadPdfBtn max-w50"></div>
                        )}
                      </>
                    </td>
                    <td className="text-center">
                      {item.amountUnit == 0 ? (
                        <p className="bonus" style={{ paddingLeft: 0 }}>
                          Gratuito
                        </p>
                      ) : (
                        <FormattedPrice price={item.amountUnit} />
                      )}
                    </td>
                  </tr>
                ))}
              </React.Fragment>
            ))}
          </tbody>
        </table>
        {orderItems.length < 1 && (
          <div className="emptyCart">
            <div>vazio</div>
          </div>
        )}
      </>
    );
  };

  const showMore = () => {
    if (pageSize > orderItems.length + 5) {
      showPopup("Não há mais produtos para carregar", "error");
    }
    setPageSize(pageSize + 5);
  };

  return (
    <div className="header-padding-main">
      <div className="container m-t100">
        <div className="col-lg-12 m-b100">
          <div className="widget">
            <h4 className="widget-title ">Meus pedidos</h4>
            <ResumeOrders />
            {pageSize <= orderItems.length && (
              <section className="container box-main">
                <div className="btn-book">
                  <button onClick={showMore}>Carregar mais</button>
                </div>
              </section>
            )}
          </div>

          <div>
            <Modal open={isOpen} onClose={() => setIsOpen(false)} sx={boxModal}>
              <Box sx={style}>
                <div>
                  <div className="layout-payments">
                    <Payments currentOrder={orderModal} />
                  </div>
                </div>
              </Box>
            </Modal>
          </div>
        </div>
      </div>
    </div>
  );
}
