import React, { useState, useContext, useRef } from "react";
import { useForm } from "react-hook-form";
import { Link, useHistory } from "react-router-dom";
import AuthContext from "../contexts/auth";
import api from "../services/config.url";
import { FormattedPrice } from "../components/FormatValues";
import showPopup from "../components/PopUp";
import Payments from "../components/payments";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
//images
import book1 from "./../assets/images/books/grid/imageNotFound.jpg";
import comboImage from "./../assets/images/combo_image.jpg";
//Components
import PageTitle from "./../layouts/PageTitle";
import { useEffect } from "react";

function ShopCart() {
  const navegate = useHistory();
  const { cart, setLoading, setCart, setSizeCart, user } =
    useContext(AuthContext);
  const [cartItems, setCartItems] = useState([]);
  const { register, handleSubmit } = useForm();
  const [discount, setDiscount] = useState([]);
  const [percentageDiscount, setPercentageDiscount] = useState([]);
  const [couponApplied, setCouponApplied] = useState(false);
  const [couponCustomerApplied, setCouponCustomerApplied] = useState(false);
  const [readyToPayment, setReadyToPayment] = useState(false);

  const prevCartRef = useRef(); // guarda uma referência ao cart anterior

  useEffect(() => {
    if (!cart) return;
    if (prevCartRef.current === cart) return; // verifica se houve mudança

    const couponInserted = cart?.discountsApplied?.filter(
      (x) => x.origin === "CUPOM"
    );
    const couponCustomerInserted = cart?.discountsApplied?.filter(
      (x) => x.origin === "CUSTOMER_DISCOUNT"
    );

    couponInserted.length > 0
      ? setCouponApplied(true)
      : setCouponApplied(false);

    if (couponCustomerInserted.length > 0) {
      setCouponCustomerApplied(true);
    } else {
      setCouponCustomerApplied(false);
    }

    setCartItems(cart.items);
    setDiscount(cart.discount);
    prevCartRef.current = cart;
  }, [cart, discount, percentageDiscount, setSizeCart]);

  const handleDeleteClick = async (cardIdItem) => {
    try {
      setLoading(true);
      const response = await api.delete(
        `/customers/cart/${cart._id}/item/${cardIdItem}`
      );
      showPopup("Removido do carrinho", "success");
      setCart(response.data);
    } catch (error) {
      if (error.response.data.message === "Unauthorized") {
        showPopup("Faça login novamente", "error");
        return navegate.push("/acesso");
      }

      showPopup(error.response.data.message, "error");
    } finally {
      setLoading(false);
    }
  };
  const ResumeCart = () => {
    if (cartItems.length === 0) {
      return <div>Carrinho vazio</div>;
    }
    const subTotal = cartItems.reduce(
      (total, value) => total + parseFloat(value?.amountUnit?.toFixed(2)),
      0
    );
    const discountValue = cart.discount || 0;
    const total = cart.amountTotal || 0; // VEM CALCULADO DO BACK

    return (
      <table className="table-bordered check-tbl m-b25">
        <tbody>
          <tr>
            <td>Subtotal</td>
            <td>
              <FormattedPrice price={subTotal} />
            </td>
          </tr>
          <tr>
            <td>Envio</td>
            <td>Gratuito</td>
          </tr>
          <tr>
            <td>Cupom</td>
            <td>
              <FormattedPrice price={discountValue} />
            </td>
          </tr>
          <tr>
            <td>Total</td>
            <td>
              <FormattedPrice price={total} />
            </td>
          </tr>
        </tbody>
      </table>
    );
  };

  const onSubmit = async (data) => {
    if (!data.cupon) {
      return showPopup("O código do cupom é obrigatório", "alert");
    }
    const payload = {
      couponCode: data.cupon,
      isPercent: false,
    };
    setCouponApplied(true);
    await handleApplyCoupon(payload);
  };

  const hasFree = cartItems.some((item) => item.amountUnit === 0);
  const hasNormal = cartItems.some((item) => item.amountUnit > 0);

  const canFinish = (hasFree && hasNormal) || hasNormal;

  const onSubmitPercentage = async () => {
    if (!canFinish) {
      showPopup(
        "Seu carrinho possui apenas item gratuíto, inclua algum caderno ou matéria",
        "alert"
      );
      return;
    }

    const payload = {
      couponCode: null,
      isPercent: true,
    };
    setCouponCustomerApplied(true);
    await handleApplyCoupon(payload);
  };

  const handleApplyCoupon = async (payload) => {
    try {
      setLoading(true);
      const response = await api.patch(`/customers/cart/${cart._id}`, payload);
      showPopup("Cupom validado com sucesso.", "success");
      setDiscount(response.data);
      setCart(response.data);
    } catch (error) {
      if (error.response.data.message === "Unauthorized") {
        showPopup("Faça login novamente", "error");
        return navegate.push("/acesso");
      }
      showPopup(error.response.data.message, "error");
    } finally {
      setLoading(false);
    }
  };

  const handleAddCart = async (data) => {
    try {
      setLoading(true);

      const cartItemIds = cartItems.map((item) =>
        item?.product?._id?.toString()
      ); // IDs do carrinho como strings

      const pendingToInclude = data.product.freeWith.filter(
        (x) => !cartItemIds.includes(x._id?.toString()) // Filtrar os que não estão no carrinho
      );

      if (pendingToInclude.length > 0) {
        let response;

        for (let i = 0; i < pendingToInclude.length; i++) {
          const { _id, amount, sku, materia, name, description } =
            pendingToInclude[i] ?? {};

          response = await api.post(`/customers/cart/${cart._id}/item`, {
            product: _id,
            amountUnit: amount,
            sku: sku,
            name: name,
            materia: materia,
            description: description,
          });
        }
        setCart(response.data);
        showPopup(
          `${
            pendingToInclude.length > 1
              ? "Produtos adicionados"
              : "Produto adicionado"
          } ao carrinho`,
          "success"
        );
        setCart(response.data);
      }
    } catch (error) {
      console.log(error);
      showPopup(error.response.data.message, "error");
    } finally {
      setLoading(false);
    }
  };

  const verifyToPayment = (valor) => {
    setReadyToPayment(valor);
  };

  const tipsPopoverOne = (props) => (
    <Tooltip
      id="button-tooltip"
      {...props}
      style={{
        position: "absolute",
        zIndex: 9999999,
        width: "max-content",
        fontSize: "14px",
        textAlign: "justify",
        backgroundColor: "#000",
        padding: "10px 10px",
        color: "red",
        borderRadius: 3,
        ...props.style,
      }}
    >
      <div style={{ textAlign: "justify" }}>
        Verifique as colaborações que enviou e os descontos conquistados no seu
        PERFIL - Minhas Colaborações
      </div>
    </Tooltip>
  );

  return (
    <div className="header-padding-main">
      <div className="page-content">
        <PageTitle parentPage="CJ" childPage="Carrinho" />
        <section className="content-inner shop-account">
          {/* <!-- Product --> */}

          {cartItems.length >= 1 ? (
            <div className="container">
              <div className="row mb-5">
                <div className="col-lg-12">
                  <div className="table-responsive">
                    <table className="table check-tbl">
                      <thead>
                        <tr>
                          <th colSpan={2}>CADERNOS</th>
                          <th style={{ textAlign: "center" }}>TOTAL</th>
                          <th className="text-end">DELETAR</th>
                        </tr>
                      </thead>
                      <tbody>
                        {cartItems.map((data, index) => (
                          <React.Fragment key={index}>
                            <tr
                              className={`${
                                data?.product?.freeWith
                                  ? "cart-item-border"
                                  : ""
                              } ${index % 2 === 0 ? "cart-item-td-bg" : ""}`}
                            >
                              <td className="product-item-img">
                                <img
                                  src={
                                    data.origin === "COMBOS"
                                      ? comboImage
                                      : (data.photos &&
                                          data.photos[0] &&
                                          data.photos[0].photo &&
                                          data.photos[0].photo.url) ||
                                        book1
                                  }
                                  alt="photo"
                                />
                              </td>
                              <td className="product-item-name">{data.name}</td>
                              <td className="product-item-price">
                                {data.amountUnit === 0 ? (
                                  <p className="bonus">Gratuito</p>
                                ) : (
                                  <FormattedPrice price={data.amountUnit} />
                                )}
                              </td>
                              <td
                                className="product-item-close"
                                style={{ marginRight: "20px" }}
                              >
                                <Link
                                  to={"#"}
                                  className="ti-close"
                                  onClick={() => handleDeleteClick(data._id)}
                                ></Link>
                              </td>
                            </tr>

                            {/* Nova linha para exibir `freeWith` abaixo da linha principal */}
                            {data?.product?.freeWith?.length > 0 && (
                              <React.Fragment>
                                <tr
                                  className={`${
                                    index % 2 === 0 ? "cart-item-td-bg" : ""
                                  }`}
                                >
                                  <td colSpan="4">
                                    <div
                                      style={{
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "space-between",
                                      }}
                                    >
                                      <div>
                                        <td
                                          colSpan="4"
                                          style={{
                                            paddingLeft: 0,
                                          }}
                                        >
                                          Gratuito na compra do(s) seguinte(s)
                                          Caderno(s):
                                        </td>
                                        {data.product.freeWith.map((item) => {
                                          const isInCart = cartItems.some(
                                            (cartItem) =>
                                              cartItem.sku === item.sku
                                          );

                                          return (
                                            <div
                                              key={item.sku}
                                              style={{
                                                display: "flex",
                                                alignItems: "center",
                                                marginLeft: "5px",
                                              }}
                                            >
                                              <Link
                                                to={`/livro-detalhes/${item.slug}`}
                                                style={{
                                                  color: isInCart
                                                    ? "green"
                                                    : "inherit",
                                                }}
                                              >
                                                {item.sku} - {item.name}
                                              </Link>
                                              {isInCart && (
                                                <div
                                                  style={{ marginLeft: "5px" }}
                                                >
                                                  {" "}
                                                  ✅
                                                </div>
                                              )}
                                            </div>
                                          );
                                        })}
                                      </div>
                                      {/* Condição para exibir o botão */}
                                      {!data.product.freeWith.every((item) =>
                                        cartItems.some(
                                          (cartItem) =>
                                            cartItem.sku === item.sku
                                        )
                                      ) && (
                                        <button
                                          className="btn btn-primary btnhover m-r20  btn-add-cart"
                                          onClick={() => handleAddCart(data)}
                                        >
                                          Incluir
                                        </button>
                                      )}
                                    </div>
                                  </td>
                                </tr>
                              </React.Fragment>
                            )}
                          </React.Fragment>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="mb-0 fullscreen">
                  {/* CUPOM DESCONTO */}
                  <div className="widget cupom m-0 col-sm-5">
                    <h4 className="widget-title desce">Cupom de desconto</h4>
                  </div>
                  <div className="col-sm-12 adjust-cupon-input">
                    <form onSubmit={handleSubmit(onSubmit)}>
                      <input
                        className="inputCupon"
                        placeholder="Código"
                        type="text"
                        name="cupon"
                        {...register("cupon")}
                      />
                      <button className="btn btn-primary btnhover m-l10">
                        Aplicar
                      </button>
                    </form>
                    {couponApplied ? (
                      <div className="applyCupon">Cupom Aplicado!</div>
                    ) : (
                      <></>
                    )}
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="mb-5 fullscreen">
                  {/* CUPOM DESCONTO PORCENTAGEM */}
                  <div className="widget cupom m-0 col-sm-5">
                    <div className="relative">
                      <h4 className="widget-title desce">
                        Desconto por colaborações
                      </h4>
                      {/* <Link
                        to="/colaboracoes/colaborador"
                        className="tip-discounts"
                      > */}
                      <div className="tip-discounts">
                        <OverlayTrigger
                          placement="bottom"
                          delay={{ show: 50, hide: 50 }}
                          overlay={tipsPopoverOne}
                        >
                          <span>?</span>
                        </OverlayTrigger>
                      </div>
                      {/* </Link> */}
                    </div>
                  </div>
                  <div className="col-sm-12 adjust-cupon-input">
                    <form onSubmit={handleSubmit(onSubmitPercentage)}>
                      <input
                        className="inputCupon"
                        placeholder="Percentual"
                        disabled={true}
                        value={`${user.percentageDiscount}%`}
                        type="text"
                        name="cupon"
                        {...register("percentageDiscount")}
                      />
                      <button
                        disabled={!user.percentageDiscount}
                        className="btn btn-primary btnhover m-l10"
                      >
                        Aplicar
                      </button>
                    </form>
                    {/* {couponCustomerApplied ? (
                      <div className="applyCupon">
                        Desconto por colaborações aplicado!
                      </div>
                    ) : (
                      <></>
                    )} */}
                  </div>
                </div>
              </div>

              <div className="col-lg-12 ">
                <div className="widget">
                  <h4 className="widget-title ">Carrinho Subtotal</h4>
                  <ResumeCart />
                </div>
              </div>
            </div>
          ) : (
            <>
              {!!readyToPayment === false && cartItems.length === 0 && (
                <div className="emptyCart">
                  <div>Carrinho vazio</div>
                </div>
              )}
            </>
          )}
          {(!!readyToPayment === true ||
            (!!readyToPayment === false && cartItems.length >= 1)) && (
            <div className="container">
              <Payments
                readyToPayment={verifyToPayment}
                canFinish={canFinish}
                customerDiscount={couponCustomerApplied}
              />
            </div>
          )}
        </section>
      </div>
    </div>
  );
}
export default ShopCart;
