import React, { useContext, useEffect, useState, useRef } from "react";
import { Link, useHistory } from "react-router-dom";
import { useForm } from "react-hook-form";
import AuthContext from "../contexts/auth";
import profile from "./../assets/images/profileWithout.png";
import api from "../services/config.url";
import showPopup from "../components/PopUp";
import InputMask from "react-input-mask";

import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import { FormattedPrice } from "../components/FormatValues";
import Box from "@mui/material/Box";
import "dayjs/locale/pt-br";
dayjs.extend(utc);
dayjs.extend(timezone);
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import {
  CAccordion,
  CAccordionItem,
  CAccordionHeader,
  CAccordionBody,
} from "@coreui/react";
import Modal from "@mui/material/Modal";
import Payments from "../components/payments";

const boxModal = {
  zIndex: "99999",
};
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "80vw",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
};

function MyProfile() {
  const { signOut, setLoading } = useContext(AuthContext);
  const [value, setValueTab] = useState(0);
  const [form, setForm] = useState([]);
  const [billingAddress, setBillingAddress] = useState({});
  const [imagemSelecionada, setImagemSelecionada] = useState(null);
  const inputRef = useRef(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [response, setResponse] = useState([]);
  const [query, setQuery] = useState({
    current: currentPage,
    pageSize: 10,
    groupBy: false,
  });
  const [orderItems, setOrderItems] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [orderModal, setOrderModal] = useState([]);
  const [pageSize, setPageSize] = useState(5);
  // const history = useHistory();
  const { register, handleSubmit, setValue, getValues } = useForm({
    defaultValues: async () =>
      api.get("/customers/profile").then((response) => {
        return response.data;
      }),
  });

  useEffect(() => {
    const formattedQuery = new URLSearchParams(query).toString();
    setLoading(true);
    api
      .get(`/customers/message/feedbacks?${formattedQuery}`)
      .then((response) => {
        setResponse(response.data);
      })
      .catch((error) => {
        const BackendError = error?.response?.data?.message;
        const genericError = "Não foi possível concluir a busca por CEP";
        showPopup(BackendError || genericError, "error");
      })
      .finally(() => {
        setLoading(false);
      });
  }, [currentPage]);

  useEffect(() => {
    //Cadastra imagem do perfil
    if (imagemSelecionada) {
      setLoading(true);
      const file = imagemSelecionada;
      const formData = new FormData();
      formData.append("file", file);
      const fileInfo = {
        fieldname: "file",
        originalName: file.name,
        mimetype: file.type,
        size: file.size,
      };
      formData.append("file", JSON.stringify(fileInfo));
      try {
        api.post("/customers/files/profile", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });
        showPopup("Foto de perfil atualizada", "success");
      } catch (error) {
        const BackendError = error?.response?.data?.message;
        const genericError = "Não foi possível alterar os dados";
        showPopup(BackendError || genericError, "error");
      } finally {
        setLoading(false);
      }
    }
  }, [imagemSelecionada]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const response = await api.get(
          `/customers/orders?current=1&pageSize=${pageSize}`
        );
        setOrderItems(response.data.records);
      } catch (error) {
        const BackendError = error?.response?.data?.message;
        const genericError = "Erro ao listar os pedidos";
        showPopup(BackendError || genericError, "error");
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, [pageSize]);

  const TranslateStatus = (props) => {
    return <>{statusMap[props.status] || ""}</>;
  };
  const handleOrder = (pedido) => {
    setOrderModal(pedido);
    setIsOpen(true);
  };
  const CustomTabPanel = (props) => {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && <div>{children}</div>}
      </div>
    );
  };
  const zipCode = async (event) => {
    if (event.length < 8) {
      return showPopup("Preencha o campo 'CEP' corretamente.", "error");
    }
    setValue("billingAddress.zipCode", event);

    setLoading(true);
    await api
      .get(`/customers/zip-codes/${event}`)
      .then((response) => {
        setValue("billingAddress", response.data);
      })
      .catch((error) => {
        const BackendError = error?.response?.data?.message;
        const genericError = "Não foi possível concluir a busca por CEP";
        showPopup(BackendError || genericError, "error");
      })
      .finally(() => {
        setLoading(false);
      });
  };
  const saveProfile = async (data) => {
    const document = data.document.replace(/[^\d]/g, "");

    if (!!data.mobilePhone === false) {
      return showPopup("Preencha o campo 'Telefone'", "error");
    }

    if (document.length !== 11) {
      return showPopup("O campo CPF precisa ter 11 digitos", "error");
    }

    setLoading(true);

    const profile = {
      name: data.name,
      secondName: data.secondName,
      email: data.email,
      nickname: data.nickname,
      profissionalGoal: data.profissionalGoal,
      currentProfession: data.currentProfession,
      mobilePhone: data.mobilePhone,
      description: data.description,
      // profession: data.profession,
      document: document,
      billingAddress,
    };

    try {
      await api.patch(`/customers/profile`, {
        profile,
      });
      showPopup("Perfil atualizado", "success");
    } catch (error) {
      const BackendError = error?.response?.data?.message;
      const genericError = "Não foi possível alterar os dados";
      showPopup(BackendError || genericError, "error");
    } finally {
      setLoading(false);
    }
  };
  const handlePagination = (page) => {
    setCurrentPage(page);
    setQuery({
      ...query,
      current: page,
    });
  };
  const ResumeCollaborations = () => {
    if (!response.records || response.records.length === 0) {
      return (
        <div className="emptyCart">
          <div>Vazio</div>
        </div>
      );
    }
    return (
      <>
        <div>
          <div className="header-collaborations d-flex">
            <h6> Total de colaborações: </h6>
            <p className="m-l10"> {response?.total}</p>
          </div>
          <div className="header-collaborations d-flex">
            <h6>Descontos conquistados: </h6>
            <p className="m-l10"> {response?.percentageDiscount}%</p>
          </div>
        </div>
        {response?.records.map((colaboration, index) => (
          <CAccordion className="mb-1 mt-2" key={index}>
            <CAccordionItem itemKey={index}>
              <CAccordionHeader>
                {dayjs
                  .tz(colaboration.createdAt, "America/Sao_Paulo")
                  .format("DD/MM/YYYY")}
              </CAccordionHeader>
              <CAccordionBody>{colaboration.sugestion}</CAccordionBody>
            </CAccordionItem>
          </CAccordion>
        ))}
      </>
    );
  };
  const profilePages = [
    {
      to: "perfil",
      icons: "far fa-user",
      name: "Perfil",
      action: () => setValueTab(0),
    },
    {
      to: "perfil",
      icons: "flaticon-shopping-cart-1",
      name: "Meus pedidos",
      action: () => setValueTab(2),
    },
    {
      to: "meus-livros/aluno",
      icons: "fa fa-briefcase",
      name: "Meus Cadernos",
      action: "",
    },
    {
      to: "perfil",
      icons: "fa-solid fa-handshake-angle",
      name: "Minhas colaborações",
      action: () => setValueTab(1),
    },
    {
      to: "acesso",
      icons: "fas fa-sign-out-alt",
      name: "Sair",
      action: signOut,
    },
  ];
  const statusMap = {
    // WAITING_PAYMENT: "Aguardando",
    REGISTERED: "Registrado",
    PAYMENT_APPROVED: "Aprovado",
    CANCELLED: "Cancelado",
    PAYMENT_REJECTED: "Rejeitado",
    DELIVERED: "Entregue",
    PAYMENT_IN_ANALYSIS: "Em analise",
    CANCEL_REQUESTED: "Cancelado",
  };
  const handleSelecionarImagem = async (evento) => {
    setImagemSelecionada(evento.target.files[0]);
  };
  const ResumeOrders = () => {
    return (
      <>
        <div className="orders">
          {orderItems.map((pedido, index) => (
            <React.Fragment key={index}>
              <CAccordion>
                <CAccordionItem itemKey={1} className="m-b10">
                  <CAccordionHeader /* className="bg-my-books-collapse" */>
                    <div className="title-order" key={pedido._id}>
                      <div className="p-r5">
                        {" "}
                        {dayjs(pedido.createdAt).format("DD/MM/YYYY")}
                      </div>
                      <div
                        className={`aditional-status  
                    ${pedido.status == "PAYMENT_APPROVED" ? "approved" : ""} 
                    ${pedido.status == "PAYMENT_REJECTED" ? "rejected" : ""} 
                    ${pedido.status == "CANCELLED" ? "rejected" : ""} 
                    ${pedido.status == "CANCEL_REQUESTED" ? "rejected" : ""} 
                    ${pedido.status == "WAITING_PAYMENT" ? "waiting" : ""}`}
                        width="13%"
                      >
                        {pedido && pedido.status ? (
                          <div className="paymentsAgain">
                            <TranslateStatus status={pedido.status} />
                            {pedido.status == "WAITING_PAYMENT" && (
                              <>
                                <button onClick={() => handleOrder(pedido)}>
                                  Pagar
                                </button>
                              </>
                            )}
                          </div>
                        ) : (
                          "Status não disponível"
                        )}
                      </div>
                      <div className="p-l5 text-center ">
                        <FormattedPrice price={pedido.amountTotal} />
                      </div>
                    </div>
                  </CAccordionHeader>
                  <CAccordionBody>
                    {pedido.items.map((item, index) => (
                      <React.Fragment key={index}>
                        <div className="my-orders-items">
                          <div className="p-0">
                            {item?.product ? (
                              <React.Fragment>
                                {item?.product?.sku} - {item.product?.name}
                              </React.Fragment>
                            ) : (
                              <React.Fragment>
                                {item?.combo.name}
                              </React.Fragment>
                            )}
                          </div>
                          <div>
                            {item.amountUnit == 0 ? (
                              <p className="bonus">Gratuito</p>
                            ) : (
                              <FormattedPrice price={item.amountUnit} />
                            )}
                          </div>
                        </div>
                        <hr></hr>
                      </React.Fragment>
                    ))}
                    {/* <div className="my-orders-amountTotal">
                      <FormattedPrice price={pedido.amountTotal} />
                    </div> */}
                  </CAccordionBody>
                </CAccordionItem>
              </CAccordion>
            </React.Fragment>
          ))}
        </div>
        {/* </table> */}
        {orderItems.length < 1 && (
          <div className="emptyCart">
            <div>vazio</div>
          </div>
        )}
      </>
    );
  };
  const showMore = () => {
    if (pageSize > orderItems.length + 5) {
      showPopup("Não há mais produtos para carregar", "error");
    }
    setPageSize(pageSize + 5);
  };

  return (
    <div className="header-padding-main bg-grey-header">
      <div className="page-content bg-white">
        <div className="content-block">
          <section
            className="content-inner"
            style={{ backgroundColor: "#f5f5f5" }}
          >
            <div className="container">
              <div className="row">
                <div className="col-xl-3 col-lg-4 m-b30">
                  <div className="sticky-top">
                    <div className="shop-account">
                      <div className="account-detail text-center">
                        <div>
                          <div>
                            <button
                              onClick={() => inputRef.current.click()}
                              className="profile-photo"
                            >
                              <img
                                src={
                                  (imagemSelecionada &&
                                    URL.createObjectURL(imagemSelecionada)) ||
                                  imagemSelecionada ||
                                  form?.photos?.[0]?.photo?.url ||
                                  profile
                                }
                                alt=""
                                style={{ maxWidth: "100%", maxHeight: "100%" }}
                              />
                              <input
                                type="file"
                                accept="image/*"
                                ref={inputRef}
                                style={{ display: "none" }}
                                onChange={handleSelecionarImagem}
                              />
                            </button>
                          </div>
                        </div>
                        <div className="account-title">
                          <div className="">
                            <h4 className="m-b5">
                              {form.nickname || "Usuário"}
                            </h4>
                          </div>
                        </div>
                      </div>
                      <ul className="account-list">
                        {profilePages &&
                          profilePages.map((item, ind) => (
                            <li key={ind}>
                              <Link
                                to={item?.to}
                                onClick={item?.action}
                                // className="active"
                              >
                                <i className={item?.icons}></i>
                                <span>{item?.name}</span>
                              </Link>
                            </li>
                          ))}
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="col-xl-9 col-lg-8 m-b30">
                  <CustomTabPanel value={value} index={0}>
                    <div className="shop-bx shop-profile">
                      <div className="shop-bx-title clearfix">
                        <h5 className="text-uppercase"> PERFIL </h5>
                      </div>
                      <form onSubmit={handleSubmit(saveProfile)}>
                        <div className="row m-b30">
                          <div className="col-lg-6 col-md-6">
                            <div className="mb-3">
                              <label className="form-label">Nome:</label>
                              <input
                                type="text"
                                placeholder="Nome"
                                className="form-control"
                                // value={form?.name || ""}
                                name="name"
                                {...register("name")}
                              />
                            </div>
                          </div>
                          <div className="col-lg-6 col-md-6">
                            <div className="mb-3">
                              <label className="form-label">Sobrenome:</label>
                              <input
                                type="text"
                                placeholder="Sobrenome"
                                className="form-control"
                                // value={form?.name || ""}
                                name="secondName"
                                {...register("secondName")}
                              />
                            </div>
                          </div>
                          <div className="col-lg-6 col-md-6">
                            <div className="mb-3">
                              <label className="form-label">CPF:</label>
                              <InputMask
                                mask={"999.999.999-99"}
                                maskChar={null}
                                type="text"
                                className="form-control"
                                defaultValue={getValues().document || ""}
                                placeholder={"Preencha com seu CPF..."}
                                name="document"
                                {...register("document")}
                              />
                            </div>
                          </div>
                          <div className="col-lg-6 col-md-6">
                            <div className="mb-3">
                              <label className="form-label">
                                Nome de usuário:
                              </label>
                              <input
                                type="text"
                                placeholder="Nome de usuário"
                                className="form-control"
                                // value={form?.nickname || ""}
                                name="nickname"
                                {...register("nickname")}
                              />
                            </div>
                          </div>

                          <div className="col-lg-6 col-md-6">
                            <div className="mb-3">
                              <label className="form-label">
                                Profissão atual:
                              </label>
                              <input
                                type="text"
                                placeholder="Profissão atual"
                                className="form-control"
                                defaultValue={
                                  getValues().currentProfession || ""
                                }
                                name="currentProfession"
                                {...register("currentProfession")}
                              />
                            </div>
                          </div>

                          <div className="col-lg-6 col-md-6">
                            <div className="mb-3">
                              <label className="form-label">
                                Objetivo profissional:
                              </label>
                              <input
                                type="text"
                                placeholder="Objetivo profissional"
                                className="form-control"
                                // value={form?.profissionalGoal || ""}
                                name="profissionalGoal"
                                {...register("profissionalGoal")}
                              />
                            </div>
                          </div>

                          <div className="col-lg-12 col-md-12">
                            <div className="mb-3">
                              <label
                                htmlFor="exampleFormControlTextarea"
                                className="form-label"
                              >
                                Outras informações:
                              </label>
                              <textarea
                                className="form-control"
                                id="exampleFormControlTextarea"
                                rows="5"
                                // value={form?.description || ""}
                                name="description"
                                {...register("description")}
                                placeholder="Complemente aqui suas descrições para que possamos auxiliá-lo(a) a alcançar seus objetivos!"
                              ></textarea>
                            </div>
                          </div>
                        </div>
                        <div className="shop-bx-title clearfix">
                          <h5 className="text-uppercase">
                            Informações de contato
                          </h5>
                        </div>

                        <div className="row">
                          <div className="col-lg-6 col-md-6">
                            <div className="mb-3">
                              <label className="form-label">Telefone:</label>
                              <InputMask
                                mask="(99) 99999-9999"
                                type="text"
                                className="form-control"
                                defaultValue={getValues().mobilePhone || ""}
                                name="mobilePhone"
                                {...register("mobilePhone")}
                              />
                            </div>
                          </div>
                          <div className="col-lg-6 col-md-6">
                            <div className="mb-3">
                              <label className="form-label">E-mail:</label>
                              <input
                                type="text"
                                className="form-control"
                                // value={form?.email || ""}
                                name="email"
                                {...register("email")}
                              />
                            </div>
                          </div>{" "}
                          <div className="col-lg-6 col-md-6">
                            <div className="mb-3">
                              <label className="form-label">País:</label>
                              <input
                                type="text"
                                className="form-control"
                                value={getValues()?.country || "Brasil"}
                                disabled
                                name="country"
                                {...register("country")}
                              />
                            </div>
                          </div>
                          <div className="col-lg-6 col-md-6">
                            <div className="mb-3">
                              <label className="form-label">CEP:</label>
                              <InputMask
                                mask="99999999"
                                maskChar={null}
                                type="text"
                                className="form-control"
                                defaultValue={
                                  getValues().billingAddress?.zipCode || ""
                                }
                                onBlur={(e) => zipCode(e.target.value)}
                                // name="zipCode"
                                // {...register("billingAddress.zipCode")}
                              />
                            </div>
                          </div>
                          <div className="col-lg-6 col-md-6">
                            <div className="mb-3">
                              <label className="form-label">Cidade:</label>
                              <input
                                type="text"
                                className="form-control"
                                disabled
                                // value={
                                //   (billingAddress &&
                                //     billingAddress?.city &&
                                //     billingAddress?.city?.name) ||
                                //   ""
                                // }
                                name="city"
                                {...register("billingAddress.city.name")}
                              />
                            </div>
                          </div>
                          <div className="col-lg-6 col-md-6">
                            <div className="mb-3">
                              <label className="form-label">
                                Endereço completo:
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                disabled
                                // value={billingAddress?.address || ""}
                                name="address"
                                {...register("billingAddress.address")}
                              />
                            </div>
                          </div>
                        </div>
                        <button
                          className="btn btn-primary btnhover mt-2"
                          // onClick={() => saveProfile()}
                        >
                          Salvar alterações
                        </button>
                      </form>
                    </div>
                  </CustomTabPanel>
                  <CustomTabPanel value={value} index={1}>
                    <div className="container">
                      <div className="col-lg-12 m-b100">
                        <div className="widget">
                          <h4 className="widget-title ">Minhas colaborações</h4>
                          <ResumeCollaborations />
                        </div>
                      </div>
                      <div className="pagination-component">
                        <Stack spacing={2}>
                          <Pagination
                            count={response.pages}
                            page={currentPage}
                            onChange={(event, page) => handlePagination(page)}
                          />
                        </Stack>
                      </div>
                    </div>
                  </CustomTabPanel>
                  <CustomTabPanel value={value} index={2}>
                    <div className="container p-0">
                      <div className="col-lg-12 m-b100">
                        <div className="widget">
                          <h4 className="widget-title ">Meus pedidos</h4>
                          <ResumeOrders />
                        </div>

                        <div>
                          <Modal
                            open={isOpen}
                            onClose={() => setIsOpen(false)}
                            sx={boxModal}
                          >
                            <Box sx={style}>
                              <div>
                                <div className="layout-payments">
                                  <Payments currentOrder={orderModal} />
                                </div>
                              </div>
                            </Box>
                          </Modal>
                        </div>
                      </div>
                    </div>
                  </CustomTabPanel>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </div>
  );
}
export default MyProfile;
